import Debug from 'debug';

import { DebugLogLevel, DebugStructuredLog, CONSOLE_SEVERITY, DebugLogLabels } from './types';

const debuggers: { [namespace: string]: Debug.Debugger } = {};

/**
 * Write to the console
 */
export function writeToConsole(
    namespace: string,
    level: DebugLogLevel,
    entry: {
        message: string;
        args: any[];
        labels?: DebugLogLabels;
    }
) {
    if (level === 'debug') {
        const debug = debuggers[namespace] || Debug(namespace);
        debuggers[namespace] = debug;

        debug(entry.message, ...entry.args);
        return;
    }

    // eslint-disable-next-line no-console
    console[CONSOLE_SEVERITY[level]](`[${namespace}] ${entry.message}`, ...entry.args);
}

/**
 * Log a structured payload.
 * https://cloud.google.com/logging/docs/structured-logging
 */
export function writeStructuredLog(log: DebugStructuredLog) {
    if (process.env.NODE_ENV !== 'test') {
        // eslint-disable-next-line no-console
        console[CONSOLE_SEVERITY[log.level]]({
            severity: log.level.toUpperCase(),
            payload: log.payload,
            ...(log.labels ? { labels: log.labels } : {}),
        });
    }
}
