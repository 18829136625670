/* eslint-disable no-console */

import { writeToConsole } from './console';
import {
    DebugLogLevel,
    DebugLoggerOptions,
    DebugLoggerFn,
    DebugLogger,
    LOG_LEVELS,
    DebugLogLabels,
} from './types';

/**
 * Create a console logger.
 */
export function Logger(
    namespace: string = 'default',
    options: DebugLoggerOptions = {}
): DebugLogger {
    const globalLabels = options.labels || {};

    return {
        info: createLogMethod(namespace, 'info', globalLabels, options),
        notice: createLogMethod(namespace, 'notice', globalLabels, options),
        error: createLogMethod(namespace, 'error', globalLabels, options),
        warning: createLogMethod(namespace, 'warning', globalLabels, options),
        debug: createLogMethod(namespace, 'debug', globalLabels, options),
        critical: createLogMethod(namespace, 'critical', globalLabels, options),
        emergency: createLogMethod(namespace, 'emergency', globalLabels, options),
        alert: createLogMethod(namespace, 'alert', globalLabels, options),
        setLabels: (labels) => {
            Object.assign(globalLabels, labels);
        },
    };
}

function shouldLog(namespace: string, level: DebugLogLevel, enableForTests: boolean = false) {
    if (process.env.NODE_ENV === 'test' && !enableForTests) {
        return false;
    }

    if (LOG_LEVELS.indexOf(level) >= LOG_LEVELS.indexOf('error')) {
        return true;
    }

    if (!process.env.GITBOOK_DEBUG || process.env.GITBOOK_DEBUG === '*') {
        return true;
    }

    return namespace.startsWith(process.env.GITBOOK_DEBUG);
}

function createLogMethod(
    namespace: string,
    level: DebugLogLevel,
    labels: DebugLogLabels,
    options: DebugLoggerOptions
): DebugLoggerFn {
    const enabled = shouldLog(namespace, level, options.enableForTests);

    // @ts-ignore function is still incomplete
    const log: DebugLoggerFn = enabled
        ? (message: string, ...args: any) =>
              writeToConsole(namespace, level, { message, args, labels })
        : () => {};

    log.stream = {
        write: (message: string) => {
            if (!enabled) {
                return;
            }
            writeToConsole(namespace, level, { message, labels, args: [] });
        },
    };

    return log;
}
