export type DebugLogLevel =
    | 'debug'
    | 'info'
    | 'notice'
    | 'warning'
    | 'error'
    | 'critical'
    | 'alert'
    | 'emergency';

/**
 * Set of labels for logs.
 */
export type DebugLogLabelValue = string | number | undefined | boolean;

export type DebugLogLabels = { [key: string]: DebugLogLabelValue };

/**
 * Structured log entry. Can be identified with labels to be queried in the Google Cloud Console
 * and aggregated in a BigQuery database.
 */
export interface DebugStructuredLog {
    level: DebugLogLevel;
    payload: any;
    labels?: DebugLogLabels;
}

/**
 * Function to log a normal message to the console.
 */
export type DebugLoggerFn = ((message: string, ...args: any[]) => void) & {
    /**
     * Stream for this logger.
     */
    stream: {
        write: (message: string) => void;
    };
};

/**
 * Function to log a structured log.
 */
export type StructuredDebugLoggerFn = (log: Omit<DebugStructuredLog, 'level'>) => void;

export type StructuredDebugLogger = {
    [key in DebugLogLevel]: StructuredDebugLoggerFn;
};

/**
 * Options when creating a debug logger.
 */
export interface DebugLoggerOptions {
    /**
     * By default, the logger is disabled in tests. Set this option to true to enable it.
     */
    enableForTests?: boolean;

    /** Labels to be set on each logs */
    labels?: DebugLogLabels;
}

export type DebugLogger = {
    [key in DebugLogLevel]: DebugLoggerFn;
} & {
    /**
     * Set the global labels for this logger.
     */
    setLabels: (labels: DebugLogLabels) => void;
};

// Map LogSeverity types to their equivalent `console.*` method.
export const CONSOLE_SEVERITY: {
    [severity in DebugLogLevel]: 'debug' | 'info' | 'warn' | 'error';
} = {
    debug: 'debug',
    info: 'info',
    notice: 'info',
    warning: 'warn',
    error: 'error',
    critical: 'error',
    alert: 'error',
    emergency: 'error',
};

export const LOG_LEVELS: DebugLogLevel[] = [
    'debug',
    'info',
    'notice',
    'warning',
    'error',
    'critical',
    'alert',
    'emergency',
];
